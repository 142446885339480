"use client";
import { Icon } from "@iconify/react";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";

const HomeSlider = ({ items = [], lang = "en", autoPlayInterval = 5000, objectFit = "cover" }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const sliderRef = useRef(null);
  const currentIndexRef = useRef(currentIndex);
  const autoPlayTimerRef = useRef(null);
  const isRTL = lang === "ar";
  

  const totalSlides = items?.length || 0;

  const goToSlide =(index) => {
    if (isAnimating || !sliderRef.current || !items?.length) return;

    setIsAnimating(true);
    const boundedIndex = Math.max(0, Math.min(index, totalSlides - 1));
    
    setCurrentIndex(boundedIndex);
    let boundedLangIndex = isRTL ? - boundedIndex: boundedIndex
    currentIndexRef.current =boundedLangIndex ;

    
    sliderRef.current?.scrollTo({
      left: boundedLangIndex * sliderRef.current.offsetWidth,
      behavior: "smooth",
    });

    setTimeout(() => setIsAnimating(false), 500);
  }

  const nextSlide = useCallback(() => {
    if (!items?.length) return;
    
    goToSlide(currentIndex + 1);
  }, [currentIndex, goToSlide, items]);

  const previousSlide = useCallback(() => {
    if (!items?.length) return;

    goToSlide(currentIndex - 1);
  }, [currentIndex, goToSlide, items]);

  // Handle auto-play
  useEffect(() => {
    if (!isHovering && autoPlayInterval && items?.length > 1) {
      autoPlayTimerRef.current = setInterval(() => {
        const nextIndex = (currentIndexRef.current + 1) % totalSlides;
        goToSlide(nextIndex);
      }, autoPlayInterval);
    }
    return () => {
      if (autoPlayTimerRef.current) {
        clearInterval(autoPlayTimerRef.current);
      }
    };
  }, [isHovering, autoPlayInterval, totalSlides, goToSlide, items]);

  // Handle scroll synchronization
  const handleScroll = useCallback(() => {
    if (isAnimating || !sliderRef.current || !items?.length) return;

    const scrollPosition = sliderRef.current.scrollLeft;
    const slideWidth = sliderRef.current.offsetWidth;
    const newIndex = Math.round(scrollPosition / slideWidth);

    if (newIndex !== currentIndexRef.current) {
      setCurrentIndex(newIndex);
      currentIndexRef.current = newIndex;
    }
  }, [isAnimating, items]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current && items?.length) {
        sliderRef.current.scrollTo({
          left: currentIndexRef.current * sliderRef.current.offsetWidth,
          behavior: "auto",
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [items]);

  // Setup scroll listener
  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    slider.addEventListener("scroll", handleScroll);
    return () => slider.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const SlideItem = ({ item }) => {
    if (!item?.image) return null;

    const ImageWrapper = ({ children }) => (
      <div className="relative w-full h-full">
        {children}
      </div>
    );

    const ImageContent = (
      <Image
        src={item.image}
        alt={item.title || "Slider image"}
        layout="fill"
        objectFit={objectFit}
        className="aspect-video rounded-button"
        priority={true}
        quality={90}
      />
    );

    return item.slug ? (
      <Link
        href={`/${lang}/news/article/${item.slug}`}
        className="block h-full cursor-pointer"
      >
        <a>
        <ImageWrapper>{ImageContent}</ImageWrapper>
        </a>
      </Link>
    ) : (
      <ImageWrapper>{ImageContent}</ImageWrapper>
    );
  };

  // Define the positions and visibility of navigation buttons based on RTL
  const showLeftButton = isRTL ? currentIndex !== totalSlides - 1 : currentIndex !== 0;
  const showRightButton = isRTL ? currentIndex !== 0 : currentIndex !== totalSlides - 1;
  
  const leftButtonAction = isRTL ? nextSlide : previousSlide;
  const rightButtonAction = isRTL ? previousSlide : nextSlide;

  return (
    <div 
      className="relative w-full h-full"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <div
        ref={sliderRef}
        className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide h-full no-scrollbar"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          WebkitOverflowScrolling: "touch",
        }}
      >
        {items?.map((item, index) => (
          <div 
            key={item?._id || index}
            className="flex-shrink-0 w-full h-full snap-center"
          >
            <SlideItem item={item} />
          </div>
        ))}
      </div>

      {/* Left Navigation Button */}
      {showLeftButton && (
        <div className="absolute z-10 left-5 top-0 bottom-5 flex items-center">
          <div
            onClick={leftButtonAction}
            className="hover:scale-110 transition-all cursor-pointer bg-white w-8 h-8 rounded-button flex justify-center items-center"
          >
            <Icon icon="ep:arrow-left-bold" color="#000" />
          </div>
        </div>
      )}

      {/* Right Navigation Button */}
      {showRightButton && (
        <div className="absolute z-10 right-5 top-0 bottom-5 flex items-center">
          <div
            onClick={rightButtonAction}
            className="hover:scale-110 transition-all cursor-pointer bg-white w-8 h-8 rounded-button flex justify-center items-center"
          >
            <Icon icon="ep:arrow-right-bold" color="#000" />
          </div>
        </div>
      )}

      {/* Pagination Dots */}
      <div className="hidden md:flex justify-center mt-4 gap-2">
        {items?.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`
              h-2 w-2 rounded-full transition-all duration-300
              ${currentIndex === index 
                ? 'bg-orange' 
                : 'bg-oeaMain hover:bg-orange/70'}
            `}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeSlider;